function checkAndLoadQualtrics(attempts = 0) {
  if (typeof QSI !== 'undefined' && QSI.API) {
    QSI.API.unload();
    QSI.API.load();
    QSI.API.run();
    console.debug('Qualtrics reloaded');
  } else {
    console.debug('QSI or QSI.API is not defined');
    if(attempts < 5) {
      setTimeout(()=>checkAndLoadQualtrics(attempts+1), 500)
    }
  }
}
